import { defineStore } from 'pinia';
import { useUserData } from './userData';

type AuthorObject = {
    avatar?: string
    first_name: string
    last_name: string
    id: number
};

type DataPicture = {
    id: number
    category?: string
    color?: string
    file: string
    name?: string
    period?: string
    desc?: string
    format?: string
    genre?: string
    width?: string
    height?: string
    materials?: string
    style?: string
    author: AuthorObject
};

export const usePicturesStore = defineStore('pictures', {
    state: () => { 
        return {
            pictures: [] as any,
            isPostsLoading: false,
            showMessageUpload: false,
            errorValidationMessage: false,
            message: {},
        }
    },
    actions: {
        async getPictures(page: number, limit: number) { 
            let offset;
            if (page === 1) offset = 0;
            if (page > 1) offset = (page * limit) - limit;
            this.isPostsLoading = true;
            try {
                const response = await useApiFetch('/pictures/', {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json;',
                    },
                    params: { limit, offset }
                });
                return this.pictures = response.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.isPostsLoading = false;
            }
        },        
        async createPicture() {
            const store = useUserData();
            const formUploadPicture = document.querySelector('#formUploadPicture') as any
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };   
            if (storage) { 
                try {
                    this.isPostsLoading = true;
                    const userLocal = JSON.parse(storage);
                    const response = await useApiFetch('/pictures/upload/', {
                        method: 'POST',
                        data: new FormData(formUploadPicture),
                        headers: {
                            'Content-Type': 'multipart/form-data;boundary=----',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                        redirect: 'follow'
                    });
                    
                    if (response.data) {
                        this.showMessageUpload = true;
                        return;
                    };
                } catch (error: any) {
                    console.error(error);
                    await store.getRefreshToken();                    
                    this.errorValidationMessage = true;
                } finally {
                    this.isPostsLoading = false;
                }
            }
        },
        async deletePictures(id: number) {             
            const store = useUserData();
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };   
            if (storage) { 
                try {
                    this.isPostsLoading = true;
                    const userLocal = JSON.parse(storage);
                    const response = await useApiFetch(`/pictures/${id}/`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'multipart/form-data;boundary=----',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                        redirect: 'follow'
                    });
                    if(response.status == 204) return { removeCard: "Работа удалена из галереи" };
                } catch (error: any) {
                    console.error(error);
                    await store.getRefreshToken();                    
                    this.errorValidationMessage = true;
                } finally {
                    this.isPostsLoading = false;
                }
            }            
        },
        changeErrorValidationMessage(toggle: boolean) { 
            return this.errorValidationMessage = toggle
        },
        changeIsPostsLoading(toggle: boolean) { 
            return this.isPostsLoading = toggle
        },
        showErrorMessage(mes: any) { 
            this.message = { ...this.message , ...mes }
            setTimeout(() => this.message = {}, 3000)
            return            
        }
    }
})